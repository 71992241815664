<template>
	<div class="app-body">
		<div class="flex_between">
			<bread-crumb></bread-crumb>
			<div class="pdtb10">
				<el-button type="danger" size="mini" class="mgr10" @click="revokeShow=true">&nbsp;&nbsp;删除&nbsp;&nbsp;</el-button>
			</div>
		</div>
		<el-card class="el-main a-fs-14 projectadd">
			<div class="fnW600 font18 mgb24 pdb10 bordrb2">{{detailData.userName}}</div>
			<div>
				<div class="flex_start mgb36">
					<div style="width:30%">
						<div class="flex_start font14 mgb8">
							<div class="w72 fontC3">电话</div>
							<div class="fontC2">{{detailData.mobile|| '--'}}</div>
						</div>
						<div class="flex_start font14 mgb8">
							<div class="w72 fontC3">标签</div>
							<div class="fontC2">{{detailData.remark|| '--'}}</div>
						</div>
						<div class="flex_start font14 mgb8">
							<div class="w72 fontC3">所属商户</div>
							<div class="fontC2">{{detailData.companyName|| '--'}}</div>
						</div>
						<div class="flex_start font14 mgb8">
							<div class="w72 fontC3">所在部门</div>
							<div class="fontC2">{{detailData.dep || '--'}}</div>
						</div>
                        
					</div>
					<div>
						<div class="flex_start font14 mgb8">
							<div class="w72 fontC3">创建时间</div>
							<div class="fontC2">{{detailData.createTimeText|| '--'}}</div>
						</div>
						<div class="flex_start font14 mgb8">
							<div class="w72 fontC3">过期时间</div>
							<div class="fontC2">{{detailData.disableTimeText|| '--'}}</div>
						</div>
						<div class="flex_start font14 mgb8">
							<div class="w72 fontC3">账号状态</div>
							<div class="fontC2">{{detailData.isValid==1?'启用':'禁用'}}</div>
						</div>
						<div class="flex_start font14 mgb8">
							<div class="w72 fontC3">员工角色</div>
							<div class="fontC2 stationTag" v-for="(item,index) in detailData.roleVoList" :key="index">
                                <span>{{ item.name }}</span>
                                <el-tooltip class="item a-ml-12" effect="dark" content="角色详情" placement="top">
                                    <i class="el-icon-question a-ml-10" @click="toRoleInfo(item)"></i>
                                </el-tooltip>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</el-card>
		<el-dialog title="提示" :visible.sync="revokeShow" width="30%">
			<div class="flex_start">
				<i class="fontC9 el-icon-info font18 mgr6"></i>
				<span class="fontC3 font14">确定删除该员工？</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="revokeShow = false">取 消</el-button>
				<el-button type="primary" @click="cancelSignAgency">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				revokeShow: false,
				userId: '',
				companyId: '',
				webList: [],
				wxList: [],
				detailData: {},
			}
		},
		created() {
			if (this.$route.query.id) {
				this.id = parseInt(this.$route.query.id)
				this.userId = parseInt(this.$route.query.userId)
				this.companyId = parseInt(this.$route.query.companyId)
				this.getDetail()
			}
		},
		methods: {
			//获取详情
			getDetail() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getCompanyUserDetail,
					params: {
						userId: this.userId,
						companyId: this.companyId
					}
				}).then(({
					data
				}) => {
					this.detailData = data
				})
			},
			//删除员工
			cancelSignAgency() {
				this.$Axios._get({
					url: this.$Config.apiUrl.delCompanyUser,
					params: {
						userId: this.userId,
						companyId: this.companyId,
					}
				}).then(res => {
					this.$message.success('删除成功!')
					this.$store.dispatch("remove_current_view", this)

				})
			},
            toRoleInfo (datas) {
                this.$router.push({
					path: '/staffManage/staff-role-details',
                    query: {
                        id: datas?datas.id:'',
                        companyId: datas?datas.companyId:'',
                    }
				})
            }
		}
	}
</script>

<style lang="scss" scoped>
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
</style>
